import React from 'react'

export default function InlineCalendly() {
  React.useEffect(() => {
    Calendly.initInlineWidget({
      url: 'https://calendly.com/joe-1337?hide_gdpr_banner=1',
      parentElement: document.getElementById('calendly-inline'),
    })
  }, [])
  return (
    <div
      id="calendly-inline"
      className="calendly-inline-widget"
      style={{ minWidth: '320px', height: '800px' }}
      data-auto-load="false"
    ></div>
  )
}
