import { Link } from 'react-router-dom'
import './Hero.scss'
// import logo2 from '../images/top-logo2.svg'
import logo3 from '../images/top-logo3.svg'

export default function Hero(props) {
  return (
    <div className="hero pt-10 pb-5 md:pb-10 lg:pb-12">
      <div className="flex justify-center">
        <Link to="/">
          <img
            src={logo3}
            className="max-w-xs md:max-w-md lg:max-w-2xl"
            alt="Jaime Sims McBride - Personal and Professional Development"
          />
        </Link>
      </div>
      {props.children}
    </div>
  )
}
