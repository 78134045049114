import { Link } from 'react-router-dom'

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Leadership', href: '/leadership' },
  { name: 'Coaching', href: '/coaching' },
  { name: 'Conflict Management', href: '/conflict-management' },
  { name: 'Workshops', href: '/workshops' },
  { name: 'Resources', href: '/resources' },
  { name: 'About', href: '/about' },
]

export default function TopNav() {
  return (
    <header className="bg-brand-600">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full pt-6 pb-6 flex items-center justify-between hidden lg:block">
          <div className="flex items-center justify-center">
            <div className="ml-10 space-x-8">
              {navigation.map(link => (
                <Link
                  key={link.name}
                  to={link.href}
                  className="text-base font-medium text-white hover:text-black hover:no-underline"
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="py-6 flex flex-wrap justify-center space-x-6 lg:hidden">
          {navigation.map(link => (
            <a
              key={link.name}
              href={link.href}
              className="text-base font-medium text-white hover:no-underline"
            >
              {link.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
  )
}
